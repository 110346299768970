import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.scrollToBottom()

    this.observer = new MutationObserver(() => {
      this.scrollToBottom()
    })

    this.observer.observe(this.element, {subtree: true, childList: true})
  }

  disconnect() {
    this.observer.disconnect()
  }

  scrollToBottom() {
    this.element.scrollTop =  this.element.scrollHeight - this.element.clientHeight
  }
}
